import React from "react";
import { StyleSheet, View, Platform, Dimensions, Image } from "react-native";
import {
  Container,
  Content,
  Card,
  Tabs,
  TabHeading,
  Item,
  Input,
  Icon,
  Text,
  Button,
  Spinner,
  Segment,
  List,
  Badge,
  CardItem
} from 'native-base';

import { default as ScalableImage } from 'react-native-scalable-image';

import { Routes } from '../navigation/routes';

import { API_URL } from "../constants/server";

const superagent = require('superagent');

import DetectDeviceService from '../lib/devicedetection';

import {
  TabView,
  TabBar,
  SceneMap,
} from 'react-native-tab-view';


import { Col, Row, Grid } from "../lib/easy-grid";

import agent from '../agent'
import { connect } from 'react-redux';

import {
	setBillId
} from '../ducks/bill';

const mapStateToProps = state => ({  });

const mapDispatchToProps = dispatch => ({
	setBillId: (id) => dispatch(setBillId(id)),
});

class BillListing extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      uri: this.props.uri,
      bills: [],
      page: 1
    }
  }

  componentDidMount() {
    this.getBills()
  }

  removeURLParameter = (url, parameter) => {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split('?');   
    if (urlparts.length >= 2) {

        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0;) {    
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
                pars.splice(i, 1);
            }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
}

  getBills = () => {
    superagent
    .get(this.state.uri)
    .set('accept', 'application/json')
    .then(res => {
      let b = []
      if(res.body.bills)
        this.setState({bills: res.body.bills})
    })
  }

  getFirstPage = () => {
    let uri = this.removeURLParameter(this.state.uri, "page")
    uri = uri + "&page=1"
    this.setState({uri : uri},() => { this.getBills() })
    this.setState({page: 1})
  }

  getLastPage = () => {
    
  }

  getPrevPage = () => {
    let prevPage = this.state.page - 1
    let uri = this.removeURLParameter(this.state.uri, "page")
    uri = uri + "&page=" + prevPage
    this.setState({uri : uri},() => { this.getBills() })
    this.setState({page: prevPage})
  }

  getNextPage = () => {
    let nextPage = this.state.page + 1
    let uri = this.removeURLParameter(this.state.uri, "page")
    uri = uri + "&page=" + nextPage
    this.setState({uri : uri},() => { this.getBills() })
    this.setState({page: nextPage})
  }

  billDetail = (bill) => {
    this.props.setBillId(bill.id);
    this.props.navigation.navigate(Routes.BILL_DETAILS, {
      // refreshCurrentTab: this.refreshCurrentTab
      bill_id: bill.id
    });
  }

  billImageUrl = (bill) => {
    console.log(bill)
    if(bill.picture == null || bill.picture == "") {
      return ""
    } else {
      return (API_URL.substring(0, API_URL.length-1) + bill.picture.url)
    }
  }

  billVote = (bill) => {
    return bill.vote != "Not Voted" ? bill.vote.voting_parameters : bill.vote
  }

  BillItem = (bill) => {
    return <Card key={bill.id}>
      <CardItem button onPress={() => this.billDetail(bill)}>
        <Grid>
          { bill.picture != null && bill.picture.url != null && <Col style={{width: DetectDeviceService.isTablet ? "40%" : "100%", marginBottom: 10}}>
            <Row>
              <ScalableImage width={DetectDeviceService.isTab ? 295 : DetectDeviceService.isTablet ? 450 : 295} resizeMode="cover" source={{uri:this.billImageUrl(bill)}} />
            </Row> 
            </Col>}
          <Row style={{  borderBottomWidth: 0.3, borderBottomColor: "#D3D3D3", marginBottom: 3, paddingBottom: 3}}>
            <Text style={{ fontWeight: "600", fontSize: DetectDeviceService.isTablet ? 15 : 14 }}>
              {bill.title}
            </Text>
          </Row>
          <Row>
            <Text style={{ color: "#666", fontStyle: 'italic', fontSize: DetectDeviceService.isTablet ? 13 : 13 }}>
              Status: {bill.status}
            </Text>
          </Row>
          <Row>
            {this.billVote(bill) == "strong_support" && <View style={[styles.support, {borderRadius: 12, height: 11, width: 11, marginTop: DetectDeviceService.isTablet ? 4 : 3 , marginRight: 3}]}></View>}
            {this.billVote(bill) == "support" && <View style={[styles.support, {borderRadius: 12, height: 11, width: 11, marginTop: DetectDeviceService.isTablet ? 4 : 3 , marginRight: 3}]}></View>}
            {this.billVote(bill) == "neutral" && <View style={[styles.neutral, {borderRadius: 12, height: 11, width: 11, marginTop: DetectDeviceService.isTablet ? 4 : 3 , marginRight: 3}]}></View>}
            {this.billVote(bill) == "strong_oppose" && <View style={[styles.oppose, {borderRadius: 12, height: 11, width: 11, marginTop: DetectDeviceService.isTablet ? 4 : 3 , marginRight: 3}]}></View>}
            {this.billVote(bill) == "oppose" && <View style={[styles.oppose, {borderRadius: 12, height: 11, width: 11, marginTop: DetectDeviceService.isTablet ? 4 : 3 , marginRight: 3}]}></View>}
            {this.billVote(bill) == "Not Voted" && <View style={[styles.not_voted, {borderRadius: 12, height: 11, width: 11, marginTop: DetectDeviceService.isTablet ? 4 : 3 , marginRight: 3}]}></View>}
            
            <Text style={{ color: "#666", fontStyle: 'italic', fontSize: DetectDeviceService.isTablet ? 13 : 13  }}>
              Me:  {bill.vote != "Not Voted" ? bill.vote.voting_parameters : bill.vote}
            </Text>
          </Row>
        </Grid>
      </CardItem>
    </Card>
  }

  refreshData = () => {
    let uri = this.props.setBillUrl()
    this.setState({uri: uri}, () => { this.getBills()})
  }

  render() {
    return (
      <Container key={this.state.uri} style={styles.container}>
        <Content>
          {
            Object.entries(this.state.bills).map(([date, bills]) => {
              return (
              <View key={date}>
                <Text style={{fontSize: 25, fontWeight: "200"}}>{date}</Text>
                {
                  bills.map((bill, index) => {
                    return (this.BillItem(bill))
                  })
                }
              </View>)
            })
          }
          <View style={{marginTop: 30, marginBottom: 30}}>
            {this.props.auth_token != "" && !this.state.loading && <Row style={{ alignItems: "center", justifyContent: "center" }}>
              {this.state.page == 1 && <Button disabled style={{marginRight: 10}}>
                <Text style={{ fontSize: 15 }}>First</Text>
              </Button>}
              {this.state.page != 1 && <Button bordered onPress={() => this.getFirstPage()} style={{marginRight: 10}}>
                <Text style={{ fontSize: 15 }}>First</Text>
              </Button>}
              {this.state.page != 1 && <Button bordered onPress={() => this.getPrevPage()} style={{marginRight: 10}}>
                <Text style={{ fontSize: 15 }}>Prev</Text>
              </Button>}
              {this.state.page == 1 && <Button disabled style={{marginRight: 10}}>
                <Text style={{ fontSize: 15 }}>Prev</Text>
              </Button>}
              {!this.state.lastPage && <Button bordered onPress={() => this.getNextPage()}>
                <Text style={{ fontSize: 15 }}>Next</Text>
              </Button>}
              {this.state.lastPage && <Button disabled style={{marginRight: 10}}>
                <Text style={{ fontSize: 15 }}>Next</Text>
              </Button>}
            </Row>}
          </View>
        </Content>
        { !DetectDeviceService.isTablet && <View style={styles.filterButton}>
            <Button info rounded onPress={() => this.props.navigation.navigate(Routes.FILTERS, {
              prevCongress: this.props.prevCongress,
              currentCongress: this.props.currentCongress,
              refresh: this.refreshData
            })}>
              <Icon name="ios-funnel" type="Ionicons" />
            </Button>
          </View> }
          { !DetectDeviceService.isTablet && <View style={styles.filterButtonText}>
            <Badge style={{ backgroundColor: "#000", width: 70, alignItems: "center", justifyContent: "center" }}>
              <Text style={{ color: "#fff" }}>Filters</Text>
            </Badge>
          </View> }
      </Container>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    // marginRight: 200,
    paddingLeft: (DetectDeviceService.isTablet ? 0 : 10),
    paddingRight: (DetectDeviceService.isTablet ? 0 : 10), 
    paddingTop: 20,
    paddingBottom: 20,
    backgroundColor: "#ececec",
    width: DetectDeviceService.isTab ? "95%" : DetectDeviceService.isTablet ? "80%" : "100%",
    alignSelf: "center",
    paddingRight: DetectDeviceService.isTablet ? 10 : 10
  },
  support: {
    backgroundColor: "#54945d"
  },
  oppose: {
    backgroundColor: "#cd5f5f"
  },
  neutral: {
    backgroundColor: "#d29824"
  },
  not_voted: {
    backgroundColor: "#b2b2b2"
  },
  filterButton: {
		position: "absolute",
    bottom: 40,
    right: 40
  },
  filterButtonText: {
    position: "absolute",
    bottom: 10,
    right: 30
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(BillListing);