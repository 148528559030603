import * as React from 'react';
import { View, StyleSheet, Platform, TouchableOpacity } from 'react-native';
import {
  Button,
  Icon,
  Text
} from 'native-base';
import { Video, ResizeMode } from 'expo-av';

import agent from '../agent'

import { CountdownCircleTimer } from 'react-native-countdown-circle-timer'

import DetectDeviceService from '../lib/devicedetection';

export default function FullScreenVideoPlayer(props) {
  const video = React.useRef(null);
  const buttonRef = React.useRef(null);

  const [status, setStatus] = React.useState({});
  const [complete, setComplete] = React.useState(false)
  const [canSkip, setCanSkip] = React.useState(false)
  const uri = props.uri;
  const organization_name = props.organization_name;
  const first_ad = props.first_ad;
  const ad = props.ad;
  const config = props.config
  const [adViewed, setAdViewed] = React.useState(false)

  let _onPlaybackStatusUpdate = playbackStatus => {
    setStatus(() => playbackStatus)
    // if (playbackStatus.durationMillis === playbackStatus.positionMillis) {
      if (playbackStatus.didJustFinish) {
        setComplete(true)
        props.closeAd()
      }

      if(!adViewed && playbackStatus.positionMillis > ((parseInt(config.time_to_skip_ad)*1000) - parseInt(config.seconds_before_skip_to_count_view)*1000)) {
        agent.Home.ad_view(ad.id)
        setAdViewed(true)
      }
  };

  const triggerNativeEventFor = (elm, event) => {
    if (!(elm instanceof Element)) {
      throw new Error(`Expected an Element but received ${elm} instead!`);
    }
  
    // const [prop, value] = Object.entries(valueObj)[0] ?? [];
    // const desc = Object.getOwnPropertyDescriptor(elm.__proto__, prop);
  
    // desc?.set?.call(elm, value);
    console.log(event)
    console.log(elm)
    elm.dispatchEvent(new MouseEvent(event, { 
            // view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1
    }));
  };
  

  let startVideo = () => {
    setTimeout(async () => {
      if(first_ad)
        video.current.playAsync()
      // buttonRef.current.press()
      // buttonRef.dispatchEvent(
      //   new MouseEvent('mousedown', {
      //       view: window,
      //       bubbles: true,
      //       cancelable: true,
      //       buttons: 1
      //   })
      // )

      // triggerNativeEventFor(document.getElementById('myButton'), 'mouseenter')

      // // triggerNativeEventFor(document.getElementById('myButton'), 'mousedown')
      // // triggerNativeEventFor(document.getElementById('myButton'), 'mouseup')
      // triggerNativeEventFor(document.getElementById('myButton'), 'click')
      // // triggerNativeEventFor(document.getElementById('myButton'), 'press')


    }, 3000)
  }

  let stopVideo = () => {
    if(canSkip || !first_ad) {
      video.current.pauseAsync()
      setComplete(true)
      props.closeAd()  
    }
  }

  if(!complete && uri != null) {
    return (
        <View style={styles.container}>
          {/* <Icon onPress={() => stopVideo()}
                name="circle-with-cross"
                style={styles.headerMenuIcon}
                type="Entypo"></Icon> */}
        <TouchableOpacity style={styles.skipAdButton} onPress={() => stopVideo()}>
            <Text style={{color: "#fff", alignSelf: "flex-start", marginTop: (DetectDeviceService.isPhone ? 25 : 5), marginRight: 5}}>Skip ad</Text>
            { (!first_ad || canSkip) && <Icon
                name="circle-with-cross"
                style={styles.headerMenuIcon}
                type="Entypo"></Icon> }
                <View style={{marginTop: (DetectDeviceService.isPhone ? 20 : 5)}}>
            { (first_ad && !canSkip) && <CountdownCircleTimer
                isPlaying
                duration={parseInt(config.time_to_skip_ad)}
                size={30}
                colors="#fff"
                strokeWidth={2}
                onComplete={() => (setCanSkip(true))}
                // colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                // colorsTime={[7, 5, 2, 0]}
              >
                {({ remainingTime }) => <View style={styles.skipCount}><Text style={{fontSize: 10, color: "#fff"}}>{remainingTime}</Text></View>}
              </CountdownCircleTimer> }
              </View>
          </TouchableOpacity>
          <Text style={{alignSelf: "flex-start", color: "#fff", marginBottom: 20, fontSize: 20, alignSelf: "center"}}>Sponsored Content {ad.organization_name != null && <Text style={{color: "#fff", fontSize: 20}}>by {ad.organization_name}</Text> }</Text>
        <Video
            ref={video}
            style={styles.video}
            source={{
            uri: uri,
            }}
            useNativeControls={false}
            resizeMode={ResizeMode.CONTAIN}
            isLooping={false}
            onPlaybackStatusUpdate={status => _onPlaybackStatusUpdate(status)}
            onLoad={() => startVideo()}
        />
        {/* { first_ad == false && Platform.OS == "ios" && <View style={styles.buttons}>
            <Button
            nativeID='myButton'
            ref={buttonRef}
            title={status.isPlaying ? 'Pause' : 'Play'}
            onPress={() =>
                status.isPlaying ? video.current.pauseAsync() : video.current.playAsync()
            }
            ></Button>
        </View> } */}
        { first_ad == false  && !status.isPlaying && <View style={styles.buttons}>
            <Button style={{height: "100%", width: "100%"}} transparent onPress={() => {(status.isPlaying ? video.current.pauseAsync() : video.current.playAsync())}}>
            <Icon name='playcircleo' type="AntDesign" style={{fontSize: 55, color: '#ffffff'}} />
            </Button>
        </View> }
        </View>
    );   
  } else {
    return null
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // justifyContent: (DetectDeviceService.isTablet ? 'flex-start' : 'flex-start'),
    backgroundColor: "rgba(0, 0, 0, 0.90)",
    zIndex: 100,
    position: "absolute",
    // top: "40%",
    // left: "5%",
    height: "110%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  headerMenuIcon: {
    fontSize: 22,
    color: "#fff",
    marginTop: DetectDeviceService.isPhone ? 25 : 5
  },
  skipAdButton: {
    marginTop: (DetectDeviceService.isPhone ? 30 : 10),
    position: "absolute",
    top: 25,
    right: 25,
    flexDirection: "row",
  },
  skipCount: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    alignSelf: "flex-end"
  },
  video: {
    alignSelf: (DetectDeviceService.isTablet ? 'flex-start' : 'flex-start'),
    width: "100%",
    height:  DetectDeviceService.isTablet ? 630 : 200,
    alignItems: "center"
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: "absolute",
    top:  (DetectDeviceService.isTablet ? "47%" : "47%"),
    left: (DetectDeviceService.isTablet ? "47%" : "40%"),
    backgroundColor: "#000000",
    height: 85,
    width: 85,
    opacity: 0.5,
    borderRadius: 10
  },
});
