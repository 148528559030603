import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = 'http://staging.yoopine.com';

const encode = encodeURIComponent;
const responseBody = res => res.body;

let token = null;
const tokenPlugin = req => {
  if (token) {
    req.set('authorization', `Token ${token}`);
  }
}

const requests = {
  del: url =>
    superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  get: url =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: (url, body) =>
    superagent.put(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  multipartput: (url, body, file) =>
    superagent.put(`${API_ROOT}${url}`).attach('user[avatar]', file).use(tokenPlugin).then(responseBody),
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody)
};

const Auth = {
  current: () =>
    requests.get('/api/v1/users/me?auth_token='+token),
  signup: (email, password) =>
    requests.post('/api/v1/users/sign_up', { user: { email, password } }),
  fbsignup: (email, auth_token, provider, facebook_id) =>
    requests.post('/api/v1/users/sign_up', { user: { email }, provider: 'facebook', facebook_id: facebook_id, auth_token: auth_token }),
  twsignup: (email, auth_token, provider, twitter_id) =>
    requests.post('/api/v1/users/sign_up?auth_token='+auth_token, { user: { email }, provider: 'twitter', twitter_user_id: twitter_id }),
  applesignup: (email, apple_token, provider, apple_id) =>
    requests.post('/api/v1/users/sign_up', { user: { email }, provider: 'apple', apple_user_id: apple_id, apple_token: apple_token }),
  googlesignupnew: (email, google_token, google_id, google_details) =>
    requests.post('/api/v2/users/sign_up', { user: { email, google_details }, provider: 'google', google_id: google_id, google_token: google_token }),
  fbsignupnew: (email, facebook_token, facebook_id, facebook_details) =>
    requests.post('/api/v2/users/sign_up', { user: { email, facebook_details }, provider: 'facebook', facebook_id: facebook_id, facebook_token: facebook_token }),
  twsignupnew: (email, twitter_token, twitter_id, twitter_details) =>
    requests.post('/api/v2/users/sign_up', { user: { email, twitter_details }, provider: 'twitter', twitter_user_id: twitter_id, twitter_token: twitter_token }),
  applesignupnew: (email, apple_token, apple_id, apple_details) =>
    requests.post('/api/v2/users/sign_up', { user: { email, apple_details }, provider: 'apple', apple_user_id: apple_id, apple_token: apple_token }),
  login: (email, password) =>
    requests.post('/api/v1/users/sign_in', { user: { email, password } }),
  googleloginnew: (google_token, google_id, email, google_details) =>
    requests.post('/api/v2/users/social_sign_in', { provider: 'google', google_id: google_id, google_token: google_token, email: email, google_details: google_details }),
  fbloginnew: (facebook_token, facebook_id, email, facebook_details) =>
    requests.post('/api/v2/users/social_sign_in', { provider: 'facebook', facebook_id: facebook_id, facebook_token: facebook_token, email: email, facebook_details: facebook_details }),
  twloginnew: (twitter_token, twitter_id, email, twitter_details) =>
    requests.post('/api/v2/users/social_sign_in', { provider: "twitter", twitter_user_id: twitter_id, twitter_token: twitter_token, email: email, twitter_details: twitter_details }),
  appleloginnew: (apple_token, apple_id, email, apple_details) =>
    requests.post('/api/v2/users/social_sign_in', { provider: "apple", apple_user_id: apple_id, apple_token: apple_token, email: email, apple_details: apple_details }),
  fblogin: (email, auth_token, provider, facebook_id) =>
    requests.post('/api/v2/users/sign_in', { user: { email }, provider: provider, facebook_id: facebook_id, auth_token: auth_token }),
  twlogin: (email, auth_token, provider, twitter_id) =>
    requests.post('/api/v2/users/sign_in?auth_token='+auth_token, { user: { email: email }, provider: "twitter", twitter_user_id: twitter_id }),
  applelogin: (email, apple_token, provider, apple_id) =>
    requests.post('/api/v2/users/sign_in', { user: { email: email }, provider: "apple", apple_user_id: apple_id, apple_token: apple_token }),
  forgotpassword: (email) =>
    requests.post('/password_resets', { email: email }),
  register: (username, email, password) =>
    requests.post('/users', { user: { username, email, password } }),
  get_personal_details: () =>
    requests.get('/api/v1/users/get_personal_details?auth_token='+token),
  update: (user) =>
    requests.put('/api/v1/users?auth_token='+token, user),
  upload_image: (image) =>
    requests.multipartput('/api/v1/users/update_avatar?auth_token='+token, {}, image),
  save: user =>
    requests.put('/user', { user }),
  deactivate: () => 
    requests.post('/api/v1/users/deactivate?auth_token='+token),
  update_device: (params) =>
    requests.put('/api/v1/devices/update_device?auth_token='+token, params)
};

const Bill = {
  voice_your_opinion: (billid, page_url, page=1) =>
    requests.get(page_url + '/' + billid + '/votes/0?auth_token='+token+'&page='+page),
  voice_your_opinion_all: (billid, page_url) =>
    requests.get(page_url + '/' + billid + '/votes/0?auth_token='+token),
  user_vote: (billid, page_url) =>
    requests.get(page_url + '/' + billid + '/votes?auth_token='+token),
  comment_opinion: (comment) => 
    requests.post('/api/v1/comments?auth_token='+token, comment),
  post_opinion: (billid, vote, btype) => 
    requests.post('/api/v1/'+btype+'/'+billid+'/votes?auth_token='+token, vote),
  update_opinion: (billid, vote_id, text, btype) =>
    requests.put('/api/v1/'+btype+'/'+billid+'/votes/'+vote_id+'?auth_token='+token, {comment_text: text, vote: {comment_text: text}}), 
  delete_opinion: (billid, voteid, btype) => 
    requests.del('/api/v1/'+btype+'/'+billid+'/votes/'+voteid+'?auth_token='+token),
  like_comment: (comment_id) =>
    requests.post('/api/v1/comments/'+comment_id+'/like?auth_token='+token),
  add_watchlist: (billid) => 
    requests.post('/api/v1/users/create_watchlist?auth_token='+token+'&bill_id='+billid),
  create_initiative: (initiative, representative_ids) => 
    requests.post('/api/v1/initiatives/create_initiatives?auth_token='+token, { initiative: initiative, representative_ids: representative_ids }),
  submit_initiative: (id) => 
    requests.put('/api/v1/initiatives/'+id+'/submit_initiative?auth_token='+token),
  email_initiatives: (id) =>
    requests.get('/api/v1/initiatives/'+id+'/emails?auth_token='+token),
  update_initiative: (initiative, initiativeid) =>
    requests.put('/api/v1/initiatives/'+initiativeid+'?auth_token='+token, { initiative: initiative }),
  vote_details: (voteid) =>
    requests.get('/api/v1/votes/details/'+voteid+'?auth_token='+token), 
  flag_comment: (comment_id, comment) =>
    requests.post('/api/v1/comments/'+comment_id+'/flag?auth_token='+token, { comment: comment }),
  issue_titles: () =>
    requests.get('/api/v1/bills/issue_titles?auth_token='+token),

}

const Representative = {
  details: (id) =>
    requests.get('/api/v2/representatives/'+id+'.json?auth_token='+token),
}

const Connect = {
  users: () =>
    requests.get('/api/v1/connect/users_list?auth_token=' + token),
  network: () =>
    requests.get('/api/v1/networkings/manage_networks_friends_and_family?auth_token=' + token),
  blocked_users: () =>
    requests.get('/api/v1/networkings/manage_blocked_users?auth_token=' + token),
  follower_users: () =>
    requests.get('/api/v1/networkings/manage_followers?auth_token=' + token),
    followers_users: () =>
    requests.get('/api/v1/networkings/manage_followings_users?auth_token=' + token),
  updatehold: (status, id) =>
    requests.put('/api/v1/networkings/' + id + '/hold_resume_postings_from_networks?auth_token=' + token + '&status=' + status),
  search: (term) => 
    requests.get('/api/v1/networkings/search_my_networks?auth_token='+token+'&search_input='+term),
  removenetwork: (id) => 
    requests.del('/api/v1/networkings/' + id + '?auth_token=' + token),
  search_users: (term) => 
    requests.get('/api/v1/connect/search_members?auth_token='+token+'&search_input='+term),
  send_invitation: (member_ids) => 
    requests.post('/api/v1/invitations/send_member_invites?auth_token='+token+'&member_ids='+member_ids),
  cancel_invitation: (invitation_id) => 
    requests.post('/api/v1/invitations/' + invitation_id + '?auth_token=' + token),
  delete_invitation: (id) => 
    requests.del('/api/v1/invitations/' + id + '?auth_token=' + token),
  invitations: () => 
    requests.get('/api/v1/invitations/member_invites_recieved?auth_token='+token),
  suggestions: () => 
    requests.get('/api/v1/connect/suggestions?auth_token='+token),
  accept_invitation: (member_id, status) =>
    requests.put('/api/v1/invitations/accept_or_decline_member_invites?auth_token='+token+'&member_ids='+member_id+'&status='+status),
  common_friends: (user_id) =>
    requests.get('/api/v1/connect/common_friends?auth_token='+token+'&user_id='+user_id),
  follow: (member_id) =>
    requests.post('/api/v1/invitations/follow_members?auth_token='+token+'&member_id='+member_id),
  unfollow: (member_id) =>
    requests.post('/api/v1/invitations/unfollow_members?auth_token='+token+'&member_id='+member_id),
}

const Share = {
  list: (page) => 
    requests.get('/api/v1/shares?auth_token='+token+'&'+'page='+page),
  details: (shareId, all_comments=true) =>
    requests.get('/api/v1/shares/'+shareId+'?auth_token='+token+(all_comments ? '' : '&take=2')), 
  create: (share) => 
    requests.post('/api/v1/shares?auth_token='+token, share),
  shareWith: (share, receiver_ids) =>
    requests.post('/api/v1/shares/share_with?auth_token='+token, { share: share, receiver_ids: receiver_ids }),
  comment: (comment) => 
    requests.post('/api/v1/share_comments?auth_token='+token, comment),
  like: (share_id) =>
    requests.post('/api/v1/shares/'+share_id+'/like?auth_token='+token),
  truth: (share_id, val) =>
    requests.post('/api/v1/shares/'+share_id+'/truth?auth_token='+token+'&truth_val='+val),
  delete: (shareId) =>
    requests.del('/api/v1/shares/'+shareId+'?auth_token='+token),
  delete_comment: (commentId) =>
    requests.del('/api/v1/share_comments/'+commentId+'?auth_token='+token),
  flag_share: (share_id, comment) =>
    requests.post('/api/v1/shares/'+share_id+'/flag?auth_token='+token, { comment: comment }),
  flag_share_comment: (share_comment_id, comment) =>
    requests.post('/api/v1/share_comments/'+share_comment_id+'/flag?auth_token='+token, { comment: comment }),
  image_video_enabled: () => 
    requests.get('/api/v1/shares/image_video_enabled?auth_token='+token)
}

const Home = {
  data: () =>
    requests.get('/api/v1/home?auth_token='+token),
  tabcount: (from_time='') =>
    requests.get('/api/v1/home/tab_count?auth_token='+token+'&from_time='+from_time),
  getcity: (city) =>
    requests.get('/api/v1/users/get_city?user[zip_code]='+city),
  getstates: () =>
    requests.get('/api/v1/blocs/state_list?auth_token='+token),
  getdistricts: (state) =>
    requests.get('/api/v1/locations/state_districts?auth_token=' + token + '&state=' + state),
  getstateslist: () =>
    requests.get('/api/v1/locations/states_list?auth_token=' + token),
  getdistrictslist: (state) =>
    requests.get('/api/v1/locations/districts_list?auth_token=' + token + '&state=' + state),
  userscount: () => 
    requests.get('/api/v1/home/users_count'),
  staterepresentatives: () => 
    requests.get('/api/v1/users/my_state_representatives?auth_token='+token),
  myrepresentatives: () => 
    requests.get('/api/v1/users/my_representatives?auth_token='+token),
  representatives: () => 
    requests.get('/api/v1/representatives.json?auth_token='+token),
  shares: (page) =>
    requests.get('/api/v1/shares?auth_token='+token+'&'+'page='+page),
  current_congress: () =>
    requests.get('/api/v1/home/current_congress'),
  fbaccess_token: (code, callback_url) =>
    requests.get('/api/v1/fbaccess_token?code='+code+"&"+"callback_url="+callback_url),
  ads: () =>
    requests.get('/api/v1/users/ads?auth_token='+token),
  ad_list: () =>
    requests.get('/api/v1/users/ad_list?auth_token='+token),
  ad_view: (ad_id) =>
    requests.post('/api/v1/users/ad_view?auth_token='+token, { ad_view: {ad_id: ad_id }}),
  ad_enabled: () =>
    requests.get('/api/v1/users/ad_enabled?auth_token='+token),
  update_ad_status: (ad_id, status) => 
    requests.post('/api/v1/users/update_ad_status?auth_token='+token, { ad: {id: ad_id, status: status}}),
}

export default {
  Auth,
	Bill,
  Connect,
  Home,
  Share,
  Representative,
  setToken: _token => { token = _token; },
  getToken: () => (token)
};

