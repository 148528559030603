import React, { Component, useCallback } from "react";
import { StyleSheet, View, TextInput, Modal, TouchableHighlight, Platform } from "react-native";
import * as AuthSession from "expo-auth-session";
import RoundRectButton from "../components/RoundRectButton";
import {
  Button,
  Text,
  Container,
  Header,
  Content,
  Form,
  Item,
  Label,
  Left,
  Right,
  Icon,
  Body,
  Title,
  Input,
  CheckBox,
	Toast
} from "native-base";

import DetectDeviceService from '../lib/devicedetection';

import * as AppleAuthentication from 'expo-apple-authentication';

import FacebookWebLogin from '../components/FacebookWebLogin';

import * as GoogleSignIn from 'expo-google-sign-in';

import { Col, Row, Grid } from "../lib/easy-grid";

import * as Facebook from 'expo-facebook';

import { API_URL } from "../constants/server";

import { Routes } from '../navigation/routes';
import { connect } from 'react-redux';
import agent from '../agent'
import {
	setAuthToken,
} from '../ducks/auth';

import AsyncStorage from '@react-native-async-storage/async-storage';

const mapStateToProps = state => ({ ...state.bill, ...state.device });

const mapDispatchToProps = dispatch => ({
	setAuthToken: (auth_token) => dispatch(setAuthToken(auth_token)),
});

const requestTokenURL = "https://www.townhallusa.com/api/v1/request/token";
const accessTokenURL = "https://www.townhallusa.com/api/v1/access_token";

const redirect = AuthSession.makeRedirectUri({
  useProxy: false,
  path: "callback.html"
});
console.log(`Callback URL: ${redirect}`);

class SignupScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      confirmpass: "",
      remember: true,
      passwordicon1: "eye-off",
      password1: true,
      passwordicon2: "eye-off",
      password2: true,
      users_count: "",
      twfunc: "",
      twtoken: "",
      twtwitterid: "",
      emptyemailmodal: false,
      emptyemail: "",
      requestTokens: "",
      requestParams: ""
    }
  }

  async componentDidMount() {
    const requestParams = this.toQueryString({ oauth_callback: redirect });
    const requestTokens = await fetch(
      requestTokenURL + requestParams
    ).then((res) => res.json());
    // this.setState({emptyemailmodal: true})
    this.setState({requestTokens: requestTokens})
    this.setState({requestParams: requestParams})
    agent.Home.userscount()
    .then(res => {
      this.setState({users_count: res.user_count})
    })
    // this.setState({emptyemailmodal: true})
  }

  signup = () => {
    if(this.state.remember) {
      AsyncStorage.setItem('@email_address', this.state.username)
    } else {
      AsyncStorage.setItem('@email_address', "")
    }
    if(this.state.password == this.state.confirmpass) {
		  agent.Auth.signup(this.state.username, this.state.password)
		  .then(res => {
        if(res.errors) {
		  		Toast.show({
                text: res.errors,
		  					duration: 5000,
                type: "danger",
                textStyle: {textAlign: "center"}
              })
        } else {
		  	  this.props.setAuthToken(res.auth_token);
          this.props.navigation.navigate(Routes.ACCOUNT_SETUP);
          agent.setToken(res.auth_token)
          AsyncStorage.setItem('@auth_token', res.auth_token)
        }
		  })
    } else {
		  Toast.show({
            text: "Confirm password doesn't match. Please re-enter!",
		  			duration: 5000,
            type: "danger",
            textStyle: {textAlign: "center"}
          })
    }
  };

  toQueryString = (params) =>{
    return (
      "?" +
      Object.entries(params)
      .map(
        ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&")
    );
  }

  createFbAccount = (token) => {
    fetch(`https://graph.facebook.com/me?access_token=${token}&fields=id,name,email`)
          .then(response => response.json())
          .then(data => {
            agent.Auth.fbsignupnew(data.email, token, data.id, data)
            .then(res => {
              if(res.errors) {
                Toast.show({
                  text: res.errors,
                  duration: 3000,
                  type: "danger",
                  textStyle: {textAlign: "center"}
                })
              } else {
                this.props.setAuthToken(res.auth_token);
                this.props.navigation.navigate(Routes.ACCOUNT_SETUP);
                agent.setToken(res.auth_token)
                AsyncStorage.setItem('@auth_token', res.auth_token)
              }
            })
          }).catch(e => console.log(e))
  }

  facebookSignup = async () => {
    try {
      await Facebook.initializeAsync({
        appId: '1637825516471711',
      });
      const {
        type,
        token,
        expires,
        permissions,
        declinedPermissions,
      } = await Facebook.logInWithReadPermissionsAsync({
        permissions: ['public_profile', 'email'],
      });
      if (type === 'success') {
        // Get the user's name using Facebook's Graph API
        fetch(`https://graph.facebook.com/me?access_token=${token}&fields=id,name,email`)
          .then(response => response.json())
          .then(data => {
            agent.Auth.fbsignupnew(data.email, token, data.id, data)
            .then(res => {
              if(res.errors) {
                Toast.show({
                  text: res.errors,
                  duration: 3000,
                  type: "danger",
                  textStyle: {textAlign: "center"}
                })
              } else {
                this.props.setAuthToken(res.auth_token);
                this.props.navigation.navigate(Routes.ACCOUNT_SETUP);
                agent.setToken(res.auth_token)
                AsyncStorage.setItem('@auth_token', res.auth_token)
              }
            })
          }).catch(e => console.log(e))
      } else {
        // type === 'cancel'
      }
    } catch ({ message }) {
				Toast.show({
              text: 'There was an error logging in to Facebook.'+message,
							duration: 3000,
              type: "danger",
              textStyle: {textAlign: "center"}
            })
    }
  }

  twitterSignup = async () => {

    try {
      // Step #1 - first we need to fetch a request token to start the browser-based authentication flow
      // const requestParams = this.toQueryString({ callback_url: redirect });

      // Step #2 - after we received the request tokens, we can start the auth session flow using these tokens
      const authResponse = await AuthSession.startAsync({
        authUrl:
          "https://api.twitter.com/oauth/authenticate" +
          this.toQueryString(this.state.requestTokens),
          returnUrl: redirect
      });

      console.log("Auth response received!", authResponse);

      // Validate if the auth session response is successful
      // Note, we still receive a `authResponse.type = 'success'`, thats why we need to check on the params itself
      if (authResponse.params && authResponse.params.denied) {
				Toast.show({
              text: 'Please authorize the app',
							duration: 3000,
              type: "danger",
              textStyle: {textAlign: "center"}
            })
      }

      // Step #3 - when the user (successfully) authorized the app, we will receive a verification code.
      // With this code we can request an access token and finish the auth flow.
      const accessParams = this.toQueryString({
        oauth_token: this.state.requestTokens.oauth_token,
        oauth_token_secret: this.state.requestTokens.oauth_token_secret,
        oauth_verifier: authResponse.params.oauth_verifier,
      });
      const accessTokens = await fetch(
        accessTokenURL + accessParams
      ).then((res) => res.json());

      console.log("Access tokens fetched!", accessTokens);

      // Now let's store the username in our state to render it.
      // You might want to store the `oauth_token` and `oauth_token_secret` for future use.
      // setUsername(accessTokens.screen_name);
      // if (accessTokens.email != "" && accessTokens.email != "null" && accessTokens.email != null) {

      agent.Auth.twsignupnew(accessTokens.email, authResponse.params.oauth_token, accessTokens.profile.twitter_id, accessTokens)
        .then(res => {
          if (res.errors) {
            Toast.show({
              text: res.errors,
              duration: 3000,
              type: "danger",
              textStyle: { textAlign: "center" }
            })
          } else {
            this.props.setAuthToken(res.auth_token);
            this.props.navigation.navigate(Routes.ACCOUNT_SETUP);
            agent.setToken(res.auth_token)
            AsyncStorage.setItem('@auth_token', res.auth_token)
          }
        })
      // } else {
      //   this.setState({twfunc: "login"})
      //   this.setState({emptyemailmodal: true})
      //   this.setState({twtoken: authResponse.params.oauth_token})
      //   this.setState({twtwitterid: accessTokens.twitter_id})
      //   this.setState({emptyemailsocial: "twitter"})
      // }
    } catch (error) {
      console.log("Something went wrong...", error);
				Toast.show({
              text: error.message,
							duration: 3000,
              type: "danger",
              textStyle: {textAlign: "center"}
            })
    } finally {

    }
  };

  about = () => {
    this.props.navigation.navigate(Routes.ABOUT, {
      page_uri: API_URL + "api/v1/about_us",
      page_title: "About Townhall USA"
    });
  }

  changeIcon = () => {
    this.setState(prevState => ({
      passwordicon1: prevState.passwordicon1 === "eye" ?  "eye-off" : "eye",
      password1: !prevState.password1
    }));
  }

  changeIcon2 = () => {
    this.setState(prevState => ({
      passwordicon2: prevState.passwordicon2 === "eye" ?  "eye-off" : "eye",
      password2: !prevState.password2
    }));
  }

  terms = () => {
    this.props.navigation.navigate(Routes.ABOUT, {
      page_uri: API_URL + "api/v1/terms_of_service",
      page_title: "Terms of Use"
    });
  }

  privacy = () => {
    this.props.navigation.navigate(Routes.ABOUT, {
      page_uri: API_URL + "api/v1/privacy_policy",
      page_title: "Privacy Policy"
    });
  }

  callTwitterFunction = () => {
    console.log(this.state.twfunc)
    if(this.state.twfunc == "login") {
      this.setState({emptyemailmodal: false});
      if (this.state.emptyemailsocial == "twitter") {
        agent.Auth.twsignup(this.state.emptyemail, this.state.twtoken, "twitter", this.state.twtwitterid)
          .then(res => {
            if (res.errors) {
              Toast.show({
                text: res.errors,
                duration: 3000,
                type: "danger",
                textStyle: { textAlign: "center" }
              })
            } else {
              this.props.setAuthToken(res.auth_token);
              this.props.navigation.navigate(Routes.ACCOUNT_SETUP);
              agent.setToken(res.auth_token)
            }
          })
      } else if (this.state.emptyemailsocial == "apple") {
        agent.Auth.applesignup(this.state.emptyemail, this.state.twtwitterid, 'apple', this.state.twtoken)
          .then(res => {
            if (res.errors) {
              Toast.show({
                text: res.errors,
                duration: 3000,
                type: "danger",
                textStyle: { textAlign: "center" }
              })
            } else {
              this.props.setAuthToken(res.auth_token);
              this.props.navigation.navigate(Routes.ACCOUNT_SETUP);
              agent.setToken(res.auth_token)
            }
          })
      }
    }
  }

  AppleAuthenticationButton = () => {
    return (
      AppleAuthentication.isAvailableAsync() && <AppleAuthentication.AppleAuthenticationButton
        buttonType={AppleAuthentication.AppleAuthenticationButtonType.CONTINUE}
        buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.WHITE}
        style={[styles.sqrbuttonSmall, {marginTop: 15}]}
        cornerRadius={25}
        onPress={async () => {
          try {
            const credential = await AppleAuthentication.signInAsync({
              requestedScopes: [
                AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                AppleAuthentication.AppleAuthenticationScope.EMAIL,
              ],
            });
            console.log(111111111)
            console.log(credential)
            // if(credential.email != null) {
              agent.Auth.applesignupnew(credential.email, credential.authorizationCode, credential.user, credential)
              .then(res => {
                if (res.errors) {
                  Toast.show({
                    text: res.errors,
                    duration: 3000,
                    type: "danger",
                    textStyle: { textAlign: "center" }
                  })
                } else {
                  this.props.setAuthToken(res.auth_token);
                  this.props.navigation.navigate(Routes.ACCOUNT_SETUP);
                  agent.setToken(res.auth_token)
                  AsyncStorage.setItem('@auth_token', res.auth_token)
                }
              })
            // } else {
            //   this.setState({twfunc: "login"})
            //   this.setState({emptyemailmodal: true})
            //   this.setState({twtoken: credential.authorizationCode})
            //   this.setState({twtwitterid: credential.identityToken})
            //   this.setState({emptyemailsocial: "apple"})
            // }
            // signed in
          } catch (e) {
            if (e.code === 'ERR_CANCELED') {
              Toast.show({
                text: "There was an error signing in."+e,
                duration: 3000,
                type: "danger",
                textStyle: {textAlign: "center"}
              })
            } else {
              Toast.show({
                text: "There was an error signing in."+e,
                duration: 3000,
                type: "danger",
                textStyle: {textAlign: "center"}
              })
            }
          }
        }}
      />
    );
  }

  initGoogleAsync = async () => {
    await GoogleSignIn.initAsync({
      // You may ommit the clientId when the firebase `googleServicesFile` is configured
    });
    this._syncUserWithStateAsync();
  };

  _syncUserWithStateAsync = async () => {
    const user = await GoogleSignIn.signInSilentlyAsync();
    console.log(user)
    agent.Auth.googlesignupnew(user.email, user.auth.access_token, user.uid, user)
    .then(res => {
      if (res.errors) {
        // if (res.code == 'open_email_popup') {
        //   this.setState({twfunc: "login"})
        //   this.setState({emptyemailmodal: true})
        //   this.setState({twtoken: credential.authorizationCode})
        //   this.setState({twtwitterid: credential.user})
        //   this.setState({emptyemailsocial: "apple"})
        // } else {
          Toast.show({
            text: res.errors,
            duration: 3000,
            type: "danger",
            textStyle: { textAlign: "center" }
          })
        // }
      } else {
        this.props.setAuthToken(res.auth_token);
        this.props.navigation.navigate(Routes.ACCOUNT_SETUP);
        agent.setToken(res.auth_token)
        AsyncStorage.setItem('@auth_token', res.auth_token)
        // agent.setToken(res.auth_token)
      }
    })
    // this.setState({ user });
  };

  signUpGoogle = async () => {
    try {
      await GoogleSignIn.askForPlayServicesAsync();
      const { type, user } = await GoogleSignIn.signInAsync();
      if (type === 'success') {
        this._syncUserWithStateAsync();
      }
    } catch ({ message }) {
      alert('login: Error:' + message);
    }
  };

  render() {
    return (
      <Container >
        <Header style={styles.container}>
          <Left style={{flex: 1}}>
            <Button transparent onPress={() => this.props.navigation.goBack()}>
              <Icon name="arrow-back" style={styles.icon}/>
            </Button>
          </Left>
          <Body style={{flex: 1}}>
            <Title style={[styles.text, styles.header]}>Townhall USA</Title>
          </Body>
          <Right style={{flex: 1}}></Right>
        </Header>
        <Content padder style={styles.container}>
        <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.emptyemailmodal}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
            }}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <Text style={{fontSize: 12, marginTop: 20}}>Enter email address to setup Townhall account.</Text>
                <Form>
                  <Item floatingLabel>
                    <Label style={styles.modal}>Email Address</Label>
                    <Input style={styles.modal} autoCapitalize='none' onChangeText={(text) => { this.setState({emptyemail: text}); }} />
                  </Item>
                </Form>
                <View style={styles.forgotactions}>
                  <Button
                    style={[ styles.forgotcancel, { backgroundColor: "#2196F3" }]}
                    onPress={() => {
                      this.setState({emptyemailmodal: false});
                    }}
                  >
                    <Text style={styles.textStyle}>Cancel</Text>
                  </Button>
                  <Button
                    style={[ styles.forgotsubmit, { backgroundColor: "#2196F3" }]}
                    onPress={this.callTwitterFunction}
                  >
                    <Text style={styles.textStyle}>Submit</Text>
                  </Button>
                </View>
              </View>
            </View>
          </Modal>
          <Text style={[styles.text, styles.login]}>Sign up</Text>
          <Text style={[styles.text, {fontSize: 15, alignSelf: "center"}]}>{this.state.users_count} Registered Members</Text>
          <Form style={{marginTop: 15}}>
            <Item style={{width: (DetectDeviceService.isTablet ? 525 : 350), alignSelf: "center", marginTop: 10}}>
              <Label style={styles.text}>Email Address: </Label>
              <Input style={styles.text} autoCapitalize='none' onChangeText={(text) => { this.setState({username: text}); }} />
            </Item>
            <Item style={{width: (DetectDeviceService.isTablet ? 525 : 350), alignSelf: "center", marginTop: 10}}>
              <Label style={styles.text}>Password: </Label>
              <Input style={styles.text} secureTextEntry={this.state.password1} autoCapitalize='none' onChangeText={(text) => { this.setState({password: text}); }} />
              <Icon style={styles.text} name={this.state.passwordicon1} onPress={() => this.changeIcon()} />
            </Item>
            <Item style={{width: (DetectDeviceService.isTablet ? 525 : 350), alignSelf: "center", marginTop: 10}}>
              <Label style={styles.text}>Confirm Password: </Label>
              <Input style={styles.text} secureTextEntry={this.state.password2} autoCapitalize='none' onChangeText={(text) => { this.setState({confirmpass: text}); }} />
              <Icon style={styles.text} name={this.state.passwordicon2} onPress={() => this.changeIcon2()} />
            </Item>
          </Form>
          <View style={[styles.actions, {marginTop: 20, alignSelf: "center", justifyContent: "center"}]}>
           <Text style={[styles.text]}><CheckBox checked={this.state.remember} style={{marginRight: 0, left: 4}}
                     onPress ={() => this.setState({ remember: !this.state.remember })}
                     />  Remember email address</Text>
          </View>
          <Grid>
           <Row style={styles.sqrbutton3Row}>
             <Col>
               <Button style={styles.sqrbutton} onPress={this.signup}><Text style={styles.buttonText}>Sign up</Text></Button>
             </Col>
           </Row>
           <Row>
           <Col>
            <Text style={[{justifyContent: "center", alignSelf: "center", marginTop: 15 }, styles.text]}>Or</Text>
            </Col>
          </Row>
           {this.AppleAuthenticationButton()}
           {Platform.OS === 'android' && <Row style={styles.sqrbutton3Row}>
             <Col>
               <Button style={styles.sqrbuttonSmall} onPress={this.signUpGoogle}><Text style={styles.buttonText}>Continue with Google</Text></Button>
             </Col>
           </Row>}
           <Row style={styles.sqrbutton3Row}>
             <Col>
               {Platform.OS != "web" && <Button style={styles.sqrbuttonSmall} onPress={this.facebookSignup}><Text style={styles.buttonText}>Continue with Facebook</Text></Button> }
               {Platform.OS == "web" && <FacebookWebLogin createFbAccount={this.createFbAccount}/>}
             </Col>
           </Row>
           <Row style={[styles.sqrbutton3Row, {marginBottom: 15}]}>
             <Col>
               <Button style={styles.sqrbuttonSmall} onPress={this.twitterSignup}><Text style={styles.buttonText}>Continue with Twitter</Text></Button>
             </Col>
           </Row>
          </Grid>
          <Button style={styles.sqrbuttonSmall} onPress={this.about}><Text style={styles.buttonText}>About Townhall USA</Text></Button>
          <Text style={styles.bottomText}>By clicking on any of the Sign up buttons, you agree to the Terms of Use and Privacy Policy</Text>
          <Row>
            <Col><Button style={[styles.extraLinks, {alignSelf: "flex-end", marginRight: (DetectDeviceService.isTablet ? 140 : 10)}]} transparent onPress={this.terms}><Text style={styles.text}>Terms of Use</Text></Button></Col> 
            <Col><Button style={[styles.extraLinks, {alignSelf: "flex-start", marginLeft: (DetectDeviceService.isTablet ? 160 : 30)}]} transparent onPress={this.privacy}><Text style={styles.text}>Privacy Policy</Text></Button></Col> 
         </Row>
        </Content>
      </Container>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: DetectDeviceService.isTablet ? "rgb(236, 236, 236)" : "rgba(43,94,170,1)"
  },
  header: {
    fontSize: 25,
    alignSelf: "center",
    width: 180
  },
  text: {
    color: DetectDeviceService.isTablet ? "rgb(87, 87, 87)" : "#FFF",
  },
  modal: {
    color: "#333",
  },
  forgotcancel: {
		height: 40,
		width: 100,
    marginRight: 20
  },
  forgotsubmit: {
		height: 40,
		width: 100
  },
  forgotactions: {
		marginTop: 20,
		flexDirection: "row",
    justifyContent: "flex-end"
  },
  login: {
    alignSelf: "center",
    fontSize: 20
  },
  actions: {
    marginTop: 30,
    alignSelf: "center",
    flexDirection: "row"
  },
  fpassword: {
    textAlign: "center"
  },
  remember: {
    textAlign: "center",
    marginTop: 10,
  },
  emailInput: {
    width: 247,
    height: 46,
    color: "#fff",
    marginTop: 49,
  },
  passwordInput: {
    width: 247,
    height: 50,
    color: "#fff",
    marginTop: 29,
  },
  extraLinks: {
    justifyContent: "center",
  },
  sqrbutton: {
    alignSelf: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255,255,255,1)",
    width: (DetectDeviceService.isTablet ? 375 : 293),
    height: 33,
  },
  sqrbuttonSmall: {
    alignSelf: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255,255,255,1)",
    width: (DetectDeviceService.isTablet ? 375 : 225),
    height: 33,
  },
  sqrbutton2: {
    width: 293,
    height: 33,
    marginTop: 20,
    marginLeft: 42
  },
  buttonText: {
    // color: "rgba(33,72,155,1)",
    color: "#000",
    fontSize: 13,
    fontWeight: "500",
  },
  buttonTextBlue: {
    color: "rgba(33,72,155,1)",
    fontSize: 17
  },
  buttonTextWhite: {
    color: "#fff",
    fontSize: 17,
  },
  sqrbutton3: {
    flexDirection: "row",
    width: 131,
    height: 33,
    justifyContent: "center",
    backgroundColor: "rgba(255,255,255,1)",
    fontSize: 17,
  },
  sqrbutton4: {
    width: 293,
    height: 33,
    marginLeft: 42,
    marginTop: -140,
    justifyContent: "center",
    fontSize: 17,
    backgroundColor: "rgba(255,255,255,1)",
  },
  sqrbutton3Row: {
    marginTop: 15,
  },
  sqrbutton5: {
    width: 150,
    height: 38,
    marginTop: 30,
    alignSelf: "center",
    backgroundColor: "rgba(43,94,170,1)",
    justifyContent: "center",
    borderColor: "#fff",
    color: "#fff",
    borderWidth: 2
  },
  sqrbutton6: {
    width: 100,
    height: 38,
    marginTop: 20,
    alignSelf: "center",
    backgroundColor: "rgba(43,94,170,1)",
    justifyContent: "center",
    borderColor: "#fff",
    color: "#fff",
    borderWidth: 2
  },
  bottomText: {
    fontSize: 13,
    color: DetectDeviceService.isTablet ? "rgb(87, 87, 87)" : "#fff",
    margin: 15,
    marginTop: 15,
    alignSelf: "center",
    textAlign: "center",
    justifyContent: "center"
  },
  centeredView: {
    flex: 1,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
		margin: 20,
		backgroundColor: "white",
    borderRadius: 20,
    paddingLeft: 35,
    paddingRight: 35,
    paddingBottom: 35,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
	icon: {
    color: DetectDeviceService.isTablet ? "#333" : "rgba(255,255,255,1)",
    fontSize: 30
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupScreen);
